html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
  height: 5px;
  ;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #88888856;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #88888856;
}

#root {
  height: 100%;
  background: white;
}

p {
  margin: 0;
}

button {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
}

button:hover {
  cursor: pointer;
}


button,
.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.ant-modal .ant-modal-content {
  padding: 0;
  background-color: #F6F6F6;
}

.ant-modal .ant-modal-content .ant-modal-header {
  display: none;
}

.ant-tabs .ant-tabs-content-holder {
  display: flex;
  flex-direction: column;
}

.ant-tabs .ant-tabs-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.ant-tabs .ant-tabs-tabpane {
  flex: 1;
  padding: 0 1.5rem;
}

.ant-input {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  line-height: 1.5;

}

.ant-input-affix-wrapper {
  height: 46px;
}

.ant-input-affix-wrapper>input.ant-input {
  padding-left: 8px;
}

/* .ant-table-thead th.ant-table-cell {
  background: #CECECE;
} */
.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-thead>tr>th p {
  background: #CECECE;
  color: #1D2129;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ant-table-wrapper .ant-table-thead>tr>th::before {
  content: none !important;
}

.ant-table-wrapper .ant-table-tbody>tr>td,
.ant-table-wrapper .ant-table-tbody>tr>td p {
  overflow: hidden;
  color: #1D2129;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ant-table-wrapper .ant-table-tbody>tr>td p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
}


.ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 24px 0;
}

.ant-pagination .ant-pagination-item {
  min-width: 50px;
  height: 50px;
}

.ant-pagination .ant-pagination-item a {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ant-pagination .ant-pagination-item-active {
  border-radius: 3.333px;
  background: #F05326;
}

.ant-pagination .ant-pagination-item-active a {
  color: #FFF;
}

.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-jump-prev,
.ant-pagination .ant-pagination-jump-next {
  min-width: 50px;
  height: 50px;
}

.ant-pagination .ant-pagination-prev a,
.ant-pagination .ant-pagination-next a,
.ant-pagination .ant-pagination-jump-prev a,
.ant-pagination .ant-pagination-jump-next a {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ant-input::placeholder,
input::placeholder {
  color: #dadada;
}

.ant-btn {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  height: 48px;
  box-shadow: none;
}

.ant-btn.ant-btn-link {
  flex: unset;
  display: inline-flex;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.ant-btn span {
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ant-btn-primary:disabled,
.ant-btn-primary.ant-btn-disabled {
  border-color: #0C719C;
  color: #fff;
  background: #0C719C;
  opacity: 0.5;
}

.ant-tabs .ant-tabs-nav {
  margin-bottom: 0;
}

.ant-tabs .ant-tabs-tabpane {
  padding: 0;
}

.ant-form-item .ant-form-item-label .ant-form-item-required::before,
.ant-form-item .ant-form-item-label .ant-form-item-required::after {
  content: none !important;
}

.ant-form-item .ant-form-item-label>label {
  color: #1D2129;

  /* Body/Body_regular */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 137.5%;
  /* 19.25px */
}

:root {
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
  --rsbs-bg: #F6F7FC;
  --rsbs-handle-bg: hwb(0 0% 100% / 0.14);
  --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);
  --rsbs-overlay-rounded: 16px;
}

.ant-btn:not(.ant-btn-icon-only)>.ant-btn-icon.ant-btn-loading-icon,
.ant-btn:not(.ant-btn-icon-only)>.ant-btn-icon:not(:last-child) {
  margin: 0;
}

.ant-btn.ant-btn-link {
  gap: 4px;
}

.ant-input-outlined:focus,
.ant-input-outlined:focus-within {
  box-shadow: 0 0 0 0.5px rgba(2, 125, 177, 0.91);
}

[data-rsbs-header] {
  padding: 0 16px !important;
  box-shadow: none !important;
}
[data-rsbs-footer] {
  padding: 8px 16px !important;
  box-shadow: none !important;
  background: #fff;
}